import { AuthService } from "src/app/shared/services/auth.service";
import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from "@angular/core";
import { ConfigService } from "../@vex/config/config.service";
import { Settings } from "luxon";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { NavigationService } from "../@vex/services/navigation.service";
import { LayoutService } from "../@vex/services/layout.service";
import { ActivatedRoute } from "@angular/router";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { SplashScreenService } from "../@vex/services/splash-screen.service";
import { VexConfigName } from "../@vex/config/config-name.model";
import { ColorSchemeName } from "../@vex/config/colorSchemeName";
import {
  MatIconRegistry,
  SafeResourceUrlWithIconOptions,
} from "@angular/material/icon";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {
  ColorVariable,
  colorVariables,
} from "../@vex/components/config-panel/color-variables";
import {
  menuAdmin,
  menuAgent,
  menuManager,
  menuMaster,
  menuMasterState,
} from "./shared/constantes/menu.enum";
import { LoadingService } from "./shared/services/loading.service";
import { StorageService } from "./shared/services/storage.service";
import { UsuariosService } from "./shared/services/usuarios.service";
import { Socket } from "ngx-socket-io";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { UtilService } from "./shared/services/util.service";

@Component({
  selector: "vex-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  loading: boolean = false;
  role: string = "";
  subscriptions = new Subscription();

  constructor(
    private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private authService: AuthService,
    private socket: Socket,
    private utilsService: UtilService
  ) {
    this.subscriptions.add(
      this.socket.fromEvent("consultFailed").subscribe((data: any) => {
        this.utilsService.showSnackbar(`${data.message}`, "error", 8000);
      })
    );
    this.loadingService.$loading.subscribe((loading) => {
      this.loading = loading;
    });

    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, "is-blink");
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case "mat":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case "logo":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case "flag":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.subscribe((queryParamMap) => {
      if (queryParamMap.has("layout")) {
        this.configService.setConfig(
          queryParamMap.get("layout") as VexConfigName
        );
      }

      if (queryParamMap.has("style")) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get("style") as ColorSchemeName,
          },
        });
      }

      if (queryParamMap.has("primaryColor")) {
        const color: ColorVariable =
          colorVariables[queryParamMap.get("primaryColor")];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color,
              },
            },
          });
        }
      }

      if (queryParamMap.has("rtl")) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get("rtl"))
            ? "rtl"
            : "ltr",
        });
      }
    });

    this.authService.currentUserRole$.subscribe(async (role) => {
      if (role !== null) {
        if (role === "ROLE_MASTER" || role === "ROLE_SUPER_ADMIN") {
          this.navigationService.items = menuMaster;
        } else if (role === "ROLE_ADMIN") {
          this.navigationService.items = menuAdmin;
        } else {
          this.navigationService.items = menuAgent;
        }
      }
    });

    /**
     * Add your own routes here
     */
  }

  async ngOnInit(): Promise<void> {
    // const response: any = await this.authService.getRoleUser();
    // response.role === "ROLE_ADMIN"
    //   ? (this.navigationService.items = menuMaster)
    //   : (this.navigationService.items = menuAgent);
  }
}
