import { LgpdRoutingModule } from "./modules/lgpd/lgpd-routing.module";
import { ErrorUnauthorizedComponent } from "./shared/components/error-unauthorized/error-unauthorized.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CustomLayoutComponent } from "./custom-layout/custom-layout.component";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";

import { LoginRouteGuard } from "./guard/login-route-guard";
import { AuthGuardService } from "./shared/services/auth-guard.service";

const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./core/auth/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "signup",
    loadChildren: () =>
      import("./core/auth/signup/signup.module").then((m) => m.SignupModule),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./core/auth/forgot-password/forgot-passord.module").then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: "",
    redirectTo: "analitico",
    pathMatch: "full",
  },
  {
    path: "",
    component: CustomLayoutComponent,
    children: [
      {
        path: "analitico",
        loadChildren: () =>
          import(
            "./modules/dashboard-analytics/dashboard-analytics.module"
          ).then((m) => m.DashboardAnalyticsModule),
        canActivate: [AuthGuardService],
        data: {
          expectedRole: [
            "ROLE_ADMIN",
            "ROLE_USER",
            "ROLE_MASTER",
            "ROLE_SUPER_ADMIN",
          ],
        },
      },
      {
        path: "consultas",
        loadChildren: () =>
          import("./modules/consultas/consultas.module").then(
            (m) => m.ConsultasModule
          ),
        canActivate: [AuthGuardService],
        data: {
          expectedRole: [
            "ROLE_ADMIN",
            "ROLE_USER",
            "ROLE_MASTER",
            "ROLE_SUPER_ADMIN",
          ],
        },
      },
      {
        path: "empresas",
        loadChildren: () =>
          import("./modules/master/empresas/empresas.module").then(
            (m) => m.EmpresasModule
          ),
        canActivate: [AuthGuardService],
        data: { expectedRole: ["ROLE_MASTER", "ROLE_SUPER_ADMIN"] },
      },
      {
        path: "contas-analise",
        loadChildren: () =>
          import(
            "./modules/administrativo/analise-contas/analise-contas.module"
          ).then((m) => m.AnaliseContasModule),
        canActivate: [AuthGuardService],
        data: {
          expectedRole: ["ROLE_ADMIN", "ROLE_MASTER", "ROLE_SUPER_ADMIN"],
        },
      },
      {
        path: "all-contas-analise",
        loadChildren: () =>
          import("./modules/master/analise-contas/analise-contas.module").then(
            (m) => m.AnaliseContasModule
          ),
        canActivate: [AuthGuardService],
        data: { expectedRole: ["ROLE_MASTER", "ROLE_SUPER_ADMIN"] },
      },
      {
        path: "all-extracts",
        loadChildren: () =>
          import("./modules/master/extrato/extrato.module").then(
            (m) => m.ExtratoModule
          ),
        canActivate: [AuthGuardService],
        data: { expectedRole: ["ROLE_MASTER", "ROLE_SUPER_ADMIN"] },
      },
      {
        path: "configuracoes",
        loadChildren: () =>
          import(
            "./modules/administrativo/configuracoes/configuracoes.module"
          ).then((m) => m.ConfiguracaoModule),
        canActivate: [AuthGuardService],
        data: {
          expectedRole: ["ROLE_ADMIN", "ROLE_MASTER", "ROLE_SUPER_ADMIN"],
        },
      },
      {
        path: "usuarios",
        loadChildren: () =>
          import("./modules/administrativo/usuarios/usuarios.module").then(
            (m) => m.UsuariosModule
          ),
        canActivate: [AuthGuardService],
        data: {
          expectedRole: ["ROLE_ADMIN", "ROLE_MASTER", "ROLE_SUPER_ADMIN"],
        },
      },
      {
        path: "usuarios-desativados",
        loadChildren: () =>
          import(
            "./modules/administrativo/usuarios-desativados/usuarios-desativados.module"
          ).then((m) => m.UsuariosDesativadosModule),
        canActivate: [AuthGuardService],
        data: {
          expectedRole: ["ROLE_ADMIN", "ROLE_MASTER", "ROLE_SUPER_ADMIN"],
        },
      },
      {
        path: "todos-usuarios",
        loadChildren: () =>
          import("./modules/master/usuarios/usuarios.module").then(
            (m) => m.UsuariosModule
          ),
        canActivate: [AuthGuardService],
        data: { expectedRole: ["ROLE_MASTER", "ROLE_SUPER_ADMIN"] },
      },
      {
        path: "todos-usuarios-desativados",
        loadChildren: () =>
          import(
            "./modules/master/usuarios-desativados/usuarios-desativados.module"
          ).then((m) => m.UsuariosDesativadosModule),
        canActivate: [AuthGuardService],
        data: { expectedRole: ["ROLE_MASTER", "ROLE_SUPER_ADMIN"] },
      },
      {
        path: "tipos-de-consultas",
        loadChildren: () =>
          import(
            "./modules/administrativo/tipos-de-consultas/tipos-de-consultas.module"
          ).then((m) => m.TiposDeConsultasModule),
        canActivate: [AuthGuardService],
        data: { expectedRole: ["ROLE_MASTER", "ROLE_SUPER_ADMIN"] },
      },
      {
        path: "credito",
        loadChildren: () =>
          import("./modules/financeiro/credito/credito.module").then(
            (m) => m.CreditoModule
          ),
        canActivate: [AuthGuardService],
        data: {
          expectedRole: [
            "ROLE_ADMIN",
            "ROLE_USER",
            "ROLE_MASTER",
            "ROLE_SUPER_ADMIN",
          ],
        },
      },
      {
        path: "extrato",
        loadChildren: () =>
          import("./modules/extrato/extrato.module").then(
            (m) => m.ExtratoModule
          ),
        canActivate: [AuthGuardService],
        data: {
          expectedRole: [
            "ROLE_ADMIN",
            "ROLE_USER",
            "ROLE_MASTER",
            "ROLE_SUPER_ADMIN",
          ],
        },
      },
      {
        path: "consultas-tabela",
        loadChildren: () =>
          import("./modules/consultas-table/consultas-table.module").then(
            (m) => m.ConsultasTableModule
          ),
        canActivate: [AuthGuardService],
        data: {
          expectedRole: [
            "ROLE_ADMIN",
            "ROLE_USER",
            "ROLE_MASTER",
            "ROLE_SUPER_ADMIN",
          ],
        },
      },
      {
        path: "consultas-lote",
        loadChildren: () =>
          import("./modules/consultas-lote/consultas-lote.module").then(
            (m) => m.ConsultasLoteModule
          ),
        canActivate: [AuthGuardService],
        data: {
          expectedRole: [
            "ROLE_ADMIN",
            "ROLE_USER",
            "ROLE_MASTER",
            "ROLE_SUPER_ADMIN",
          ],
        },
      },
      {
        path: "lgpd",
        loadChildren: () =>
          import("./modules/lgpd/lgpd.module").then((m) => m.LgpdModule),
        canActivate: [AuthGuardService],
        data: {
          expectedRole: [
            "ROLE_ADMIN",
            "ROLE_USER",
            "ROLE_MASTER",
            "ROLE_SUPER_ADMIN",
          ],
        },
      },
      {
        path: "configuracoes-conta",
        loadChildren: () =>
          import("./modules/account-settings/account-settings.module").then(
            (m) => m.AccountSettingsModule
          ),
        canActivate: [AuthGuardService],
        data: {
          expectedRole: [
            "ROLE_ADMIN",
            "ROLE_USER",
            "ROLE_MASTER",
            "ROLE_SUPER_ADMIN",
          ],
        },
      },
      {
        path: "error-unauthorized",
        loadChildren: () =>
          import(
            "./shared/components/error-unauthorized/error-unauthorized.module"
          ).then((m) => m.errorUnauthorizedModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "corrected",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule, QuicklinkModule],
})
export class AppRoutingModule {}
